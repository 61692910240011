import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/people.css";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import { people, post } from "../../assets/Images/ImagesLink";
import { Link } from "react-router-dom";
import axios from "../../API/axios";
import { ShimmerThumbnail } from "react-shimmer-effects";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { ShimmerDiv } from "shimmer-effects-react";
import ModalForGuest from "../ModalForGuest";
import CompanyInteraction from "./CompanyInteraction";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Helmet } from "react-helmet";
import PeopleFeedAds from "../People/PeopleFeedAds";
import AdSense from "./AdSense";

const Companies = () => {
  const options = [
    { value: "apple", label: "Apple" },
    { value: "banana", label: "Banana" },
    { value: "cherry", label: "Cherry" },
    { value: "grape", label: "Grape" },
  ];
  const guestModeData = localStorage.getItem("guestMode");

  const sessionId = localStorage.getItem("sessionId");
  const userUid = localStorage.getItem("userUid");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  //************************Company Start  ************************//
  const [isLoading, setIsLoading] = useState(true);
  const [companyList, setcompanyList] = useState([]);
  const [searchcompanyList, setSearchcompanyList] = useState([]);
  const [nextBatchLoading, setNextBatchLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isError, setIsError] = useState(false);
  const [searchUserName, setSearchUserName] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getCompanyList = (page, searchUserName) => {
    setNextBatchLoading(true);
    const data = {
      deviceType: "Web",
      pageNumber: page,
      pageSize: 24,
    };
    axios
      .post(`Company/GetAllCompaniesList?Search=${searchUserName}`, data)
      .then((response) => {
        console.log("comp", response.data.responseData.companiesList);
        setIsLoading(false);
        // const res = response.data.responseData.companiesList;
        // setcompanyList(res);

        setTotalPages(response.data.responseData.paging.totalPages);
        // setPostList((prev) => [...prev, ...resData.data.responseData.postdetails]);
        if (page >= response.data.responseData.paging.totalPages) {
          setHasMoreData(false);
        }
        const newPosts = response.data.responseData.companiesList;
        console.log("newPosts", newPosts);
        // Ensure only unique jobs are added to the job list
        // setcompanyList((prev) => {
        //   const postSet = new Set(prev?.map((post) => post?.compUid)); // Assuming job objects have a unique 'id' field
        //   const uniqueNewPosts = newPosts.filter(
        //     (post) => !postSet.has(post?.compUid)
        //   );
        //   return [...prev, ...uniqueNewPosts];
        // });

        if (searchUserName !== "") {
          // return newPosts;
          setcompanyList([]);
          setSearchcompanyList(newPosts);
        } else {
          setSearchcompanyList([]);
          setcompanyList((prev) => {
            const postSet = new Set(prev?.map((post) => post?.compUid)); // Assuming job objects have a unique 'id' field
            const uniqueNewPosts = newPosts.filter(
              (post) => !postSet.has(post?.compUid)
            );
            return [...prev, ...uniqueNewPosts];
          });
        }

        if (page >= totalPages) {
          setNextBatchLoading(false); // Stop loading if the last page is reached
        } else {
          setNextBatchLoading(false); // Continue loading for more pages
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setIsError(true);
        } else {
          console.log(error);
        }
        setIsLoading(false);
        setNextBatchLoading(false);
      });
  };


  const getUserListWithAds = (posts) => {
    const result = [];
    posts.forEach((post, index) => {
      result.push(post);
      // Insert AdComponent after every 12 posts
      if ((index + 1) % 12 === 0) {
        result.push({ isAd: true }); // Add a placeholder object for ads
      }
    });
    return result;
  };

  const CompanyWithAds = getUserListWithAds(companyList);

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = (index) => {
    setShowDropdown((prev) => (prev === index ? null : index));
  };

  useEffect(() => {
    setcompanyList([]); // Reset user list when searchUserName changes
    getCompanyList(1, searchUserName); // Start from page 1 for a new search
  }, [searchUserName]);

  useEffect(() => {
    if (page <= totalPages) {
      getCompanyList(page, searchUserName);
    }
  }, [page]);

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);
  //************************Company End  ************************//
  //************************ Follow User Start  ************************//
  const [isfollowed, setIsfollowed] = useState("");
  const [followedCompanies, setFollowedCompanies] = useState({});
  console.log("followedCompanies", followedCompanies);

  //************************ Follow Company Start  ************************//

  const FollowCompany = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      uiD_ID: id,
    };
    try {
      await axios.post("Entity/Follow/Company", data).then((resData) => {
        console.log("followed");
        getCompanyList();
        // setIsfollowed(true);
        setFollowedCompanies((prev) => ({ ...prev, [id]: true }));
        setToastMessage(resData.data.message);
        setShowToast(true);
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Follow Company End  ************************//

  //************************ UnFollow Company Start  ************************//
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [compUID, setCompUID] = useState("");
  const UnFollow = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      uiD_ID: compUID,
    };
    try {
      await axios.post("Entity/Unfollow/Company", data).then((resData) => {
        console.log("resData", resData);
        getCompanyList();
        // toast.success(resData.data.message);
        setIsfollowed(false);
        setFollowedCompanies((prev) => ({ ...prev, [compUID]: false }));
        setToastMessage(resData.data.message);
        setShowToast(true);
        handleCloseModal();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ UnFollow Company End  ************************//

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [showModal]);

  const [followModal, setFollowModal] = useState(false);
  const closeModalData = () => {
    setFollowModal(false);
  };
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    scrollToTop();
  }, []);
  return (
    <>
      <Helmet>
        <title>Companies | Welinkjobs</title>
        <meta name="description" content="Take your career to new heights. Explore top companies and exciting job openings on our portal. Your future starts here." />
        {/* <meta name="keywords" content="react, meta tags, seo" /> */}
        {/* <meta name="author" content="Your Name" /> */}
        <meta property="og:title" content="Welinkjobs, Your Career Hub: Discover Top Companies" />
        <meta property="og:description" content="Take your career to new heights. Explore top companies and exciting job openings on our portal. Your future starts here." />
        {/* <meta property="og:image" content="https://example.com/image.jpg" /> */}
        <meta property="og:url" content="https://welinkjobs.com/Companies" />
      </Helmet>
      <div className="friend-main-container mt-3">
        <Row>
          <div className="headerSection  d-block d-md-flex justify-content-between">
            <Col md={7}>
              <div className="friendsHeaderText">
                <h1>Companies</h1>
              </div>
            </Col>
            <Col md={5}>
              <div>
                <input
                  type="email"
                  className="form-control p-2"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Search Company"
                  onChange={(e) => {
                    setSearchUserName(e.target.value);
                    getCompanyList();
                  }}
                  value={searchUserName}
                />
              </div>
            </Col>
          </div>
        </Row>
        <div className="frindsCardContainer">
          <Row>
            {isLoading ? (
              <div className="row mt-2">
                <div className="col-md-6">
                  <ShimmerDiv mode="light" height={200} width={"100%"} />
                  {/* <ShimmerThumbnail height={200} mode="light" /> */}
                </div>
                <div className="col-md-6">
                  <ShimmerDiv mode="light" height={200} width={"100%"} />
                  {/* <ShimmerThumbnail height={200} mode="dark" /> */}
                </div>
                <div className="col-md-6 mt-2">
                  <ShimmerDiv mode="light" height={200} width={"100%"} />
                </div>
                <div className="col-md-6 mt-2">
                  <ShimmerDiv mode="light" height={200} width={"100%"} />
                </div>
                <div className="col-md-6 mt-2">
                  <ShimmerDiv mode="light" height={200} width={"100%"} />
                </div>
                <div className="col-md-6 mt-2">
                  <ShimmerDiv mode="light" height={200} width={"100%"} />
                </div>
              </div>
            ) : isError ? (
              <div className="mt-2 error-image-cont">
                <img src={post.errorbig} alt="404 Not Found" />
              </div>
            ) :
              searchcompanyList.length === 0
                ? CompanyWithAds.map((item, index) => {
                  // Check if the current item is an ad
                  if (item.isAd) {
                    return (
                      <div className="PeopleNewsFeed" style={{ height: '200px' }}>
                        <AdSense />
                      </div>
                    )
                  }
                  const isCompanyFollowed =
                    followedCompanies[item.compUid] || item.isFollowedByYou;
                  return (
                    <>
                      <Col md={6} key={index} className="d-flex align-items-stretch">
                        <div className="PeopleCard" style={{ width: '100%' }}>
                          <div className="PeopleCardHeader">
                            <img
                              src={
                                item.backGroundImage === null ||
                                  item.backGroundImage === ""
                                  ? people.background
                                  : item.backGroundImage
                              }
                              alt=""
                              width="100%"
                              height="120px"
                            />
                            <div className="overlay"></div>
                            <div className="centerAlignment">
                              <Link to={`/Company/${item.compUid}`}>
                                <div className="">
                                  <img
                                    src={
                                      item.logoImage === null
                                        ? people.DefaultProfile
                                        : item.logoImage
                                    }
                                    alt=""
                                    srcset=""
                                    style={{ borderRadius: "50px" }}
                                    width={50}
                                    height={50}
                                  />
                                </div>
                              </Link>
                              <div className=" d-block d-md-flex align-items-baseline justify-content-between">
                                <div className=" ">
                                  <div className="mainCardText mb-0">
                                    <Link to={`/Company/${item.compUid}`}>
                                      <h1>{item.compName}</h1>
                                    </Link>
                                  </div>
                                  <div className="mainCardText">
                                    <h1>{item.nameTag}</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="PeopleCardContainer p-2 ms-3  d-flex align-items-baseline justify-content-between">
                            <div>
                              <div className="mainCardText"></div>

                              <div className="cardSubText">
                                <h1>{item.industryName}</h1>
                              </div>
                            </div>
                            <div
                              className="follower-button d-flex flex-column align-items-end"
                              style={{ width: "100px" }}
                            >
                              {console.log(
                                "isCompanyFollowed",
                                isCompanyFollowed
                              )}

                              <CompanyInteraction
                                item={item}
                                isCompanyFollowed={item.isFollowedByYou}
                                index={index}
                                followedCompanies={followedCompanies}
                                setFollowedCompanies={setFollowedCompanies}
                                getCompanyList={getCompanyList}
                              />

                              <div className="cardSubText mt-2">
                                <h1>{item.follwersCounts} followers</h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  );
                })
                : (
                  searchcompanyList &&
                  searchcompanyList?.map((item, index) => {
                    console.log("companyitem", item);
                    const isCompanyFollowed =
                      followedCompanies[item.compUid] || item.isFollowedByYou;
                    return (
                      <Col md={6} key={index}>
                        <div className="PeopleCard">
                          <div className="PeopleCardHeader">
                            <img
                              src={
                                item.backGroundImage === null ||
                                  item.backGroundImage === ""
                                  ? people.background
                                  : item.backGroundImage
                              }
                              alt=""
                              width="100%"
                              height="120px"
                            />
                            <div className="overlay"></div>
                            <div className="centerAlignment">
                              <Link to={`/Company/${item.compUid}`}>
                                <div className="">
                                  <img
                                    src={
                                      item.logoImage === null
                                        ? people.DefaultProfile
                                        : item.logoImage
                                    }
                                    alt=""
                                    srcset=""
                                    style={{ borderRadius: "50px" }}
                                    width={50}
                                    height={50}
                                  />
                                </div>
                              </Link>
                              <div className=" d-block d-md-flex align-items-baseline justify-content-between">
                                <div className=" ">
                                  <div className="mainCardText mb-0">
                                    <Link to={`/Company/${item.compUid}`}>
                                      <h1>{item.compName}</h1>
                                    </Link>
                                  </div>
                                  <div className="mainCardText">
                                    <h1>{item.nameTag}</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="PeopleCardContainer p-2 ms-3  d-flex align-items-baseline justify-content-between">
                            <div>
                              <div className="mainCardText">
                                {/* <h1>{item.title}</h1> */}
                              </div>
                              {/* <div className="cardSubText">
                              <h1>{item.headLine}</h1>
                            </div> */}
                              <div className="cardSubText">
                                <h1>{item.industryName}</h1>
                              </div>
                            </div>
                            <div
                              className="follower-button d-flex flex-column align-items-end"
                              style={{ width: "100px" }}
                            >
                              {console.log("isCompanyFollowed", isCompanyFollowed)}

                              <CompanyInteraction
                                item={item}
                                isCompanyFollowed={isCompanyFollowed}
                                index={index}
                                followedCompanies={followedCompanies}
                                setFollowedCompanies={setFollowedCompanies}
                                getCompanyList={getCompanyList}
                              />

                              <div className="cardSubText mt-2">
                                <h1>{item.follwersCounts} followers</h1>
                              </div>
                            </div>

                          </div>
                        </div>
                      </Col>
                    );
                  })
                )}
            {hasMoreData ? (''
            ) : (
              <p className="text-center">No more data available</p>
            )}
            {nextBatchLoading && (
              <div className="text-center">
                <img src={post?.loader} width={50} />
              </div>
            )}
          </Row>
        </div>
      </div>

      {showModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Unfollow User</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseModal();
                  }}
                />
              </div>

              <div className="col-md-12 text-end mt-4 d-flex justify-content-center">
                <button
                  type="button"
                  className="mainBtn1"
                  onClick={() => UnFollow()}
                >
                  Unfollow
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {followModal && <ModalForGuest closeModalData={closeModalData} />}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default Companies;
